import { Draggable } from "gsap/all";

import { SetAudioSpeed } from "./TireAudio";
import { updateNoise } from "./NoiseCircle";

let tire;
let vertical = false;

const CreateTire = function( window ) {
  return new Promise(function(resolve) {
    const material = getTireMaterial();
    // const type = 'object';
    const type = "collada";
    loadTireObject(type).then(function(mesh) {
      tire = mesh;
      console.log(tire);
      tire.material = material;

      updateTireRotation( window );

      tire.geometry.center();
      const scale = 0.1;
      tire.geometry.scale(scale, scale, scale);
      tire.position.z = -40;

      const light = getLight();
      console.log(light);
      resolve([tire, light]);
    });
  });
};

function getLight() {
  // const light = new THREE.PointLight( 0xffffff, 2, 100, 0.5 );
  const light = new THREE.DirectionalLight(0xffffff, 2);
  light.position.set( 0, 0, 1 );
  return light;
}

function getTireMaterial() {
  return new THREE.MeshPhongMaterial({
    color: 0,
    emissive: 0,
    specular: 3552822,
    shininess: 6.38,
  });
}

 function loadTireObject ( type ) {

  let modelSrc;

  return new Promise(function(resolve) {
    
    if (type === 'collada') {
      const loader = new THREE.ColladaLoader();
      loader.options.convertUpAxis = true;
      modelSrc = '/models/tire-collada.dae';
      loader.load(modelSrc, function (collada) {
        const object = collada.scene.children[0].children[0].children[0];
        // console.log('COLLADA');
        // console.log(collada);
        // console.log('OBJECTS');
        // console.log(object);
        resolve( object );
      });
    } else {
      modelSrc = '/models/tyre-simple.obj';
      const objLoader = new THREE.OBJLoader();
      objLoader.load(modelSrc, function (object) {
        resolve(object.children[0]);
      });
    }
  });
  
}

// RESIZING
// ----------------------
const updateTireRotation = function ({ innerWidth: width, innerHeight: height }) {
  const ratio = width / height;
  console.log(ratio);

  tire.rotation.y = 0;

  if (ratio < 0.8) {
    vertical = true;
    tire.rotation.z = 0;
    tire.rotation.x = Math.PI / 2;
    console.log('VERTICAL');
  } else {
    vertical = false;
    tire.rotation.x = 0;
    tire.rotation.z = Math.PI / 2;
  }
};

// INTERACTION
// ------------------
const scale = 0.005;
let lastX = 0;
let lastY = 0;
let meshX = 0;
let meshY = 0;

let sound;
let soundPlaying = false;

const SetupSwiping = function ( container, render ) {
    
  // Setup dragging
  const dragger = new Draggable(document.createElement("div"), {
    // onDragStart: function (){},
    onDrag: function () {
      dragAction(this, render);
    },
    onThrowUpdate: function () {
      dragAction(this, render);
    },
    trigger: container,
    throwProps: true,
    throwResistance: 5000
  });
  // Run render on GSAP tick
  // TweenLite.ticker.addEventListener("tick", render);
};

function dragAction( event, render ) {
  var x = event.x;
  var y = event.y;

  var dx = x - lastX;
  var dy = y - lastY;

  lastX = x;
  lastY = y;

  meshX += dx;
  meshY += dy;

  // tire.rotation.x = meshY * scale;
  let delta;
  if ( vertical ) {
    tire.rotation.x = meshY * scale;
    delta = -event.deltaY;
  } else {
    tire.rotation.y = meshX * scale;
    delta = event.deltaX;
  }

  SetAudioSpeed( delta );
  updateNoise( delta );
  render();
}

export { CreateTire, SetupSwiping, updateTireRotation };