let sound;
let playing = false;
import Pizzicato from "pizzicato";

const LoadAudio = function ( el ) {
  return new Promise(function( resolve ) {   
    
    const params = {
      source: 'file',
      // https://archive.org/details/78_jungle-boogie_the-bobby-true-trio-brodsky-handman-tepper-trio_gbia0001554b/Jungle+Boogie+-+The+Bobby+True+Trio+-+Brodsky.flac
      // https://archive.org/details/78_praise-the-lord-and-pass-the-ammunition_frank-loesser-the-southern-sons_gbia0000392a
      // options: { path: "/jungle-boogie.mp3", loop: true }
      // options: { path: "/respect.mp3", loop: true }
      options: { path: "/car.mp3", loop: true }
    };

    sound = new Pizzicato.Sound(params, function() {
      console.log('sound loaded');
      // startAudio();
      resolve();
    });
    
    // resolve();
    el.addEventListener('click', function play () {
      startAudio( el );
      el.removeEventListener('click', play);
    }, false);
    el.addEventListener('touchstart', function play () {
      startAudio( el );
      el.removeEventListener('touchstart', play);
    }, false);
  });
  

};

function startAudio( el ) {
  if (playing) return;
  console.log('PLAY SOUND');
  playing = true;
  sound.play();
  sound.sourceNode.playbackRate.value = 0;
}

// SET SPEED
// ----------------
function SetAudioSpeed ( delta ) {
  if (!sound || !sound.sourceNode) return;
  const speed = Math.min((delta / 50), 1);
  sound.sourceNode.playbackRate.value = Math.abs(speed);
}

export { LoadAudio, SetAudioSpeed };