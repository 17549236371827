import React from "react";

const vertex = `
  varying vec2 vUv;
  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`;

const frag = `
  varying vec2 vUv;
  uniform float delta;
  uniform float t;

  #define PI 3.14159265359

  float rand(vec2 co)
  {
      return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
  }

  mat2 rotate2d(float _angle){
      return mat2(cos(_angle),-sin(_angle),
                  sin(_angle),cos(_angle));
  }


  void main() {
      vec2 st = vUv;
      float pct = 0.;
      st -= vec2(0.5);
      st = rotate2d( sin(t)*PI ) * st;
      st += vec2(0.5);
      float noise = rand(st);

      float deltaMod = min(delta, 120.);
      float noiseMod = .3 - sqrt(abs(deltaMod))/40.;
      noiseMod = max(noiseMod,0.05);

      pct=distance(st,vec2(.5))*10.*2.*noiseMod;
      pct = step(noise,pct);
      vec3 color = vec3(pct);
      color = 1.0 - color;
      float grey = 0.1;
      color = max(vec3(grey),color);

      gl_FragColor = vec4(color,1.);
  }
`;

const Shaders = function () {
  return (
      <>
        <script dangerouslySetInnerHTML={{__html: vertex}} id="vertexShader" type="x-shader/x-vertex" />
        <script dangerouslySetInnerHTML={{__html: frag}} id="fragmentShader" type="x-shader/x-fragment" />
      </>
  );
};

export default Shaders;