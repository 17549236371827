import React, {useState, useEffect} from 'react';
import { Transition } from "react-transition-group";

const duration = 1000;

const defaultStyle = {
  transition: `opacity ${duration}ms ease`,
  opacity: 1,
};

const transitionStyles = {
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const LoadingOverlay = function ({ loaded }) {

  const [clicked, setClicked] = useState(false);
  const [mobile, setMobile] = useState(false);
  
  let hide = loaded && clicked;
  
  useEffect(() => {
    setMobile(require("current-device").default.mobile());
  });
  
  let clickWord = mobile ? "Tap" : "Click";
  let text = loaded ? `${clickWord} to start` : 'Loading...';

  return (
    <Transition in={!hide} timeout={duration} unmountOnExit>
      {state => (
        <div
          className="loader"
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (loaded) {
              setClicked(true);
            }
          }}
        >
          {console.log(text)}
          <p>
            <span>{text}</span>
            {mobile && loaded && (
              <>
                <br />
                <span>(and unmute your phone)</span>
              </>
            )}
          </p>
        </div>
      )}
    </Transition>
  );
};



export default LoadingOverlay;