import React, { Component } from 'react';
import LoadingOverlay from './LoadingOverlay';
import {
  CreateTire,
  SetupSwiping,
  updateTireRotation
} from "../scene/TireScene";
import { LoadAudio } from "../scene/TireAudio";
import { CreateNoise, updateNoiseMeshSize } from "../scene/NoiseCircle";
import Shaders from "../scene/shaders";


let activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development";

// Interaction vars
let MOUSE_DOWN = false;

export default class SceneComponent extends Component {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.state = {
      loaded: false
    };
    // this.clock = new THREE.Clock()
  }

  
  componentDidMount = () => {
    const loadjs = require('loadjs');
    this.loadThree( loadjs )
      .then( this.loadThreeUtils.bind(null, loadjs) )
      .then(() => {
        this.setupCamera();
        this.setupRenderer();
        this.setupScene();
        this.setupLights();
        this.buildScene()
          .then(() => {
            // this.animate();
            this.renderScene();
            this.render();
            this.setState({
              loaded: true
            });
          });
      });
  }


  loadThree = ( loadjs ) => {
    const threePath = '/three.101.min.js';
    return new Promise(function(resolve, reject) {
      loadjs([threePath], 'three');
      loadjs.ready('three', {
        success: resolve,
        error: reject
      });
    });
  }

  loadThreeUtils = ( loadjs ) => {
    // const loadjs = require('loadjs');
    const utils = [
      '/ColladaLoader.js',
      '/OBJLoader.js',
      '/ThrowPropsPlugin.js'
    ];
    return new Promise(function(resolve, reject) {
      loadjs(utils, 'threeUtils');
      loadjs.ready('threeUtils', {
        success: resolve,
        error: reject
      });
    });
  }

  setupCamera = () => {
    let { innerWidth: width, innerHeight: height } = window;
    this.camera = new THREE.PerspectiveCamera(45, width / height, 10, 1000);
    this.camera.position.set(0, 0, 0);
  }

  setupRenderer = () => {
    let { innerWidth: width, innerHeight: height } = window;
    
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      preserveDrawingBuffer: false
    });

    const pixelRatio = Math.max(2, window.devicePixelRatio);
    this.renderer.setPixelRatio(pixelRatio);
    this.renderer.setSize(width, height);
    // Setup resize events
    window.addEventListener('resize', () => this._resize());
    window.addEventListener('orientationchange', () => this._resize());
  }

  setupScene = () => {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x0000ff);
  }

  buildScene = () => {
    return new Promise((resolve) => {
      const tireReady = CreateTire( window );
      const audioReady = LoadAudio( this.containerElement );
      const noiseMesh = CreateNoise( this.camera, window );
      Promise.all([ tireReady, audioReady ]).then(values => {
        // Add noise mesh
        this.scene.add( noiseMesh );
        // Add tire
        this.tire = values[0][0];
        this.scene.add( this.tire );
        // Add light
        this.light = values[0][1];
        this.scene.add(this.light);
        // Setup swiping
        SetupSwiping( this.containerElement, this.renderScene );
        resolve();
      });
      
    });
  }

  setupLights = () => {
    
  }

  animate = () => {
    // Start loop again
    console.log('animate');
    requestAnimationFrame(this.animate);
    this.renderScene();
  }

  renderScene = () => {
    // console.log('render scene');
    this.renderer.render(this.scene, this.camera);
  }

  // COMPONENT RENDER
  render() {
    return (
      <div className="three-container" ref={element => this.containerElement = element}>
        <LoadingOverlay loaded={this.state.loaded} />
        <canvas id="three-canvas" ref={element => this.canvas = element} />
        <Shaders />
      </div>
    );
  }

  // EVENTS
  _resize() {
    let { innerWidth: width, innerHeight: height } = window;
    updateNoiseMeshSize( this.camera, window );
    updateTireRotation(window);
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderScene();
  }
}
