let uniforms, mesh, meshSize, time;

const CreateNoise = function ( camera, window ) {

  const planeZ = -100;
  
  time = 0;
  uniforms = {
    delta: { type: "f", value: 0.0 },
    t: { type: "f", value: time },
  };
  
  // updateSizeUniforms( renderer );
  // setupShaderEvents();

  meshSize = getBackgroundSize(camera, planeZ, window);
  const geometry = new THREE.PlaneGeometry(
    meshSize.width,
    meshSize.height,
    1,
    1
  );
  
  const material = new THREE.ShaderMaterial( {
    uniforms: uniforms,
    vertexShader: document.getElementById( 'vertexShader' ).textContent,
    fragmentShader: document.getElementById( 'fragmentShader' ).textContent
  });

  mesh = new THREE.Mesh( geometry, material );
  mesh.position.z = planeZ;

  return mesh;
};


function getBackgroundSize(
  camera,
  planeZ,
  { innerWidth: width, innerHeight: height }
) {

  const cameraZ = camera.position.z;
  const distance = cameraZ - planeZ;
  const aspect = width / height;
  const vFov = camera.fov * Math.PI / 180;
  const planeHeightAtDistance = 2 * Math.tan(vFov / 2) * distance;
  const planeWidthAtDistance = planeHeightAtDistance * aspect;
  
  return {
    width: planeWidthAtDistance,
    height: planeHeightAtDistance
  };
}


function setupShaderEvents () {
  document.onmousemove = function(e){
    uniforms.u_mouse.value.x = e.pageX;
    uniforms.u_mouse.value.y = e.pageY;
  };
}

// const updateSizeUniforms = function ( renderer ) {
//   uniforms.u_resolution.value.x = renderer.domElement.width;
//   uniforms.u_resolution.value.y = renderer.domElement.height;
// };

const updateNoiseMeshSize = function(camera,window) {
  const planeZ = mesh.position.z;
  const { width, height } = getBackgroundSize(camera, planeZ, window);
  const scale = {
    x: width / meshSize.width,
    y: height / meshSize.height,
  };
  mesh.scale.x = scale.x;
  mesh.scale.y = scale.y;
};

const updateNoise = function ( delta ) {
  // console.log(delta);
  time += 0.001;
  uniforms.t.value = time;
  console.log(uniforms.delta.t);
  uniforms.delta.value = delta;

};

export { CreateNoise, updateNoiseMeshSize, updateNoise };